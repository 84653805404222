import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
text1: darkMode ? '#FFFFFF' : '#000000',
text2: darkMode ? '#C3C5CB' : '#565A69',
text3: darkMode ? '#6C7284' : '#888D9B',
text4: darkMode ? '#565A69' : '#C3C5CB',
text5: darkMode ? '#2C2F36' : '#EDEEF2',

// backgrounds / greys
bg1: darkMode ? '#121416' : '#FFFFFF', // Darker dark mode background
bg2: darkMode ? '#1F2228' : '#F7F8FA', // Darker background for dark mode
bg3: darkMode ? '#2B2F39' : '#EDEEF2', // Slightly darker grey background
bg4: darkMode ? '#3A3E49' : '#CED0D9', // Darker grey
bg5: darkMode ? '#4B4F5A' : '#888D9B', // Darker background for dark mode

// specialty colors
modalBG: darkMode ? 'rgba(0,0,0,.5)' : 'rgba(0,0,0,0.3)', // Darker modal background
advancedBG: darkMode ? 'rgba(0,0,0,0.15)' : 'rgba(255,255,255,0.6)', // Darker advanced BG for dark mode

// primary colors
primary1: darkMode ? '#1C4A7F70' : '#0066FF', // Change pink to blue (darker for dark mode)
primary2: darkMode ? '#3178CC' : '#66B3FF', // Change pink to blue
primary3: darkMode ? '#4D84D1' : '#A3C7FF', // Change pink to blue
primary4: darkMode ? '#336B9B70' : '#B0D9FF', // Slightly darker for dark mode
primary5: darkMode ? '#1C4A7F70' : '#CCE4FF', // Slightly darker for dark mode

// color text
primaryText1: darkMode ? '#6DA8FF' : '#0077FF', // Change pink to blue for text

// secondary colors
secondary1: darkMode ? '#1D62B8' : '#0066FF', // Blue
secondary2: darkMode ? '#0F001A26' : '#B0D9FF', // Darker for dark mode
secondary3: darkMode ? '#0F001A26' : '#CCE4FF', // Darker for dark mode

// other colors
red1: '#FF6871', // Leave as is (since it's not specified to change)
red2: '#F82D3A', // Leave as is (since it's not specified to change)
green1: '#27AE60', // Leave as is (since it's not specified to change)
yellow1: '#FFE270', // Leave as is (since it's not specified to change)
yellow2: '#F3841E' // Leave as is (since it's not specified to change)


    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primaryText1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primaryText1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  letter-spacing: 0.05em;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: "Orbitron", sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 20px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: black;
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  background-image: ${({ theme }) =>
    `radial-gradient(50% 50% at 50% 50%, ${transparentize(0.9, theme.primary1)} 0%, ${transparentize(
      1,
      theme.bg1
    )} 100%)`};
}
`
